.mainContainer {
  display: flex;
  gap: 0.5em;
  width: fit-content;
  height: fit-content;
  z-index: 10;
}

.radio {
  display: none;
}

.star {
  width: max-content;
  height: max-content;
  cursor: pointer;
}

@media screen and (max-width: 964px) {
  .star {
    width: 3em;
    height: auto;
  }
}
