.mainContainer {
  width: 100vw;
  height: 100dvh;
  background-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.ptrn {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100dvh;
  object-fit: cover;
}

.headerContainer {
  width: 90%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: fit-content;
  gap: 4em;
  color: var(--tertiary-color);
  font-size: 1.5em;
  cursor: pointer;
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  gap: 1em;
  padding: 0.5em 1em 0.5em 1em;
  border: 0.1em solid var(--white);
  border-radius: 1em 0 1em 0;
  background-color: var(--primary-color);
  z-index: 10;
}

#input {
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--white);
}

#input::placeholder {
  color: var(--white);
}

.mainContent {
  width: 90%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}

.middelContainer {
  display: flex;
}

.leftSide {
  width: 50%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.title {
  width: 80%;
  word-spacing: 100vw;
  text-align-last: right;
  font-size: 6.3vw;
  margin: 0 0 0.5em 0;
}

.tagContainer {
  display: flex;
  gap: 1em;
}

.tag {
  padding: 0.5em 1em 0.5em 1em;
  background-color: var(--secondary-color);
  width: fit-content;
  border-radius: 0.5em;
  color: black;
  font-weight: 500;
  font-size: 1.5vw;
  cursor: pointer;
}

.rightSide {
  width: 50%;
  height: 70dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img {
  width: 80%;
  object-fit: contain;
  z-index: 1;
}

.bottomContainer {
  display: flex;
  width: 100%;
  align-items: center;
  height: fit-content;
}

.bottomLeft {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.btn {
  width: fit-content;
  height: fit-content;
  padding: 0.5em 1em 0.5em 1em;
  border-radius: 1em 0 1em 0;
  background-color: var(--tertiary-color);
  color: var(--white);
  font-size: 1.8em;
  border: none;
  cursor: pointer;
  z-index: 1;
}

.peopleContainer {
  display: flex;
  align-items: center;
  gap: 1em;
}

.amounth {
  width: 1.8em;
  height: 1.8em;
  border-radius: 50%;
  padding: 1em;
  border: 0.1em solid var(--secondary-color);
  cursor: pointer;
  z-index: 1;
}

#count {
  font-size: 1.8em;
}

.starContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: fit-content;
}

@media screen and (max-width: 964px) {
  .filterContainer {
    font-size: 1.2em;
  }

  #input {
    width: 30vw;
  }

  .middelContainer {
    flex-direction: column;
  }

  .title {
    font-size: 12vw;
  }

  .tag {
    font-size: 3.5vw;
  }

  .rightSide {
    width: 100%;
    height: fit-content;
    margin: 2em 0 2em 0;
  }

  .img {
    width: 60vw;
  }

  .bottomContainer {
    flex-direction: column-reverse;
    gap: 1em;
  }

  .bottomLeft {
    width: 90%;
  }

  .btn {
    padding: 1em;
    font-size: 4vw;
  }

  .peopleContainer {
    gap: 0.5em;
  }

  .amounth {
    width: 4vw;
    height: 4vw;
    border-radius: 50%;
    padding: 1em;
    border: 0.1em solid var(--secondary-color);
    cursor: pointer;
    z-index: 1;
  }

  .peopleIcon {
    width: 8vw;
  }

  #count {
    font-size: 6vw;
  }
}
